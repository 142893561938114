import { SearchData } from "../../models/searchData";

type Action={
    type:string;
    value:any;
}

const initialValue = new SearchData()

export default function searchDataReducer(searchData:SearchData, action:Action) {
    let price;
    if(searchData.type === "sale"){price = [0,5000000]}
    if(searchData.type === "annual"){price = [0,5000]}
    if(searchData.type === "seasonal" || searchData.type === "weekly"){price = [0,30000]}

    switch (action.type) {
        case ("TypeOfSearch"):{
            return(
                {
                    ...initialValue,
                    person:{...initialValue.person},
                    stay:{...initialValue.stay},
                    typeOfArticle:[...initialValue.typeOfArticle],
                    type:action.value,
                    price:price,
                    filters: [...initialValue.filters]
                }
            )
        }
        case ("InitTypeOfSearch"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    type:action.value,
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("minPrice"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price: !!searchData.price ? [action.value,searchData.price[1]] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("maxPrice"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price: !!searchData.price ? [searchData.price[0],action.value] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("nbrPerson"):{
            return(
                {
                    ...searchData,
                    person:action.value,
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("addTypeOfArticle"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:action.value,
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("removeTypeOfArticle"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:action.value,
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("roomsRangeMin"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    roomsRange: !!searchData.roomsRange ? [action.value,searchData.roomsRange[1]] : [action.value,6],
                    filters: [...searchData.filters]
                }
            )
        }
        case ("roomsRangeMax"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    roomsRange: !!searchData.roomsRange ? [searchData.roomsRange[0],action.value] : [1,action.value],
                    filters: [...searchData.filters]
                }
            )
        }
        case ("surface"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    minSurface: action.value,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("stay"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:action.value,
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("filters"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: action.value
                }
            )
        }
        case ("typeOfSearchReset"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    type:initialValue.type,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("priceReset"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price: price,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("resetAll"):{
            return(
                {
                    ...initialValue,
                    person:{...initialValue.person},
                    stay:{...initialValue.stay},
                    typeOfArticle:initialValue.typeOfArticle,
                    price: price,
                    filters: [...initialValue.filters]
                }
            )
        }
        case ("resetFilterModule"):{
            return(
                {
                    ...searchData,
                    person:{...initialValue.person},
                    stay:{...initialValue.stay},
                    typeOfArticle:initialValue.typeOfArticle,
                    price:null,
                    minSurface:null,
                    roomsRange:null,
                    filters: [...initialValue.filters]
                }
            )
        }
        case ("personReset"):{
            return(
                {
                    ...searchData,
                    person:{...initialValue.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("typeOfArticleReset"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:initialValue.typeOfArticle,
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("roomReset"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    roomsRange: initialValue.roomsRange,
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("surfaceReset"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...searchData.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    minSurface: initialValue.minSurface,
                    filters: [...searchData.filters]
                }
            )
        }
        case ("stayReset"):{
            return(
                {
                    ...searchData,
                    person:{...searchData.person},
                    stay:{...initialValue.stay},
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    filters: [...searchData.filters]
                }
            )
        }
        default:{
            throw Error('Unknown action: ' + action.type);
        }
    }
}


