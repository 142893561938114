import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { LocaleNavLink } from "@ct-react/locale";
import { useLocation } from "react-router-dom";
import { useVisitor } from "@ct-react/visitor";
import { useQuery } from "@apollo/client";
import { classNames } from "@ct-react/core";
import useIsHomePage from "../../hooks/useIsHomepage";
import { CART_BADGE_GQL_DATA } from "../../../shared/fragments";
import "./navigation.scss";
import { MenuContext } from "../../contexts/menu";

const UserMenu:FunctionComponent = ()=>{
    const intl = useIntl();
    const { isLoggedIn } = useVisitor();
    const location = useLocation();
    const isHome = useIsHomePage();

    const target = useMemo(() =>
      encodeURIComponent(location.pathname + location.search), [ location ]);

    if (isLoggedIn)
      return (
        <div className={classNames("userMenu",{primary:isHome, secondary:!isHome})}>
            <ul>
                <li>
                <a href={`/logout/?target=${target}`}>
                    {intl.formatMessage({id:"menu-logOut",defaultMessage:"Se déconnecter"})}
                </a>
                </li>
            </ul>
      </div>
      );

    return (
        <div className={classNames("userMenu",{primary:isHome, secondary:!isHome})}>
            <ul>
            <li>
                <a href={`/signup/?target=${target}`}>
                {intl.formatMessage({id:"signin", defaultMessage:"S'inscrire"})}
                </a>
            </li>
            <li>
                <a href={`/login/?target=${target}`}>
                {intl.formatMessage({id:"login",defaultMessage:"Se connecter"})}
                </a>
            </li>
            </ul>
        </div>
    );

};

export const ProfilIcone:FunctionComponent = ()=>{
    const location = useLocation();
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const { isLoggedIn } = useVisitor();
    const render = ()=> isClicked? <UserMenu/> : "";

    useEffect(()=>{setIsClicked(false)},[location]);

    return(
        <div className={classNames("profil",{isLogged:isLoggedIn})} onClick={()=>setIsClicked(!isClicked)}>
            <FontAwesomeIcon className="icone user" icon={faUser}/>
                {render()}
        </div>
    )
};
export const WeatherIcone:FunctionComponent = ()=>{
    const intl = useIntl();

    return(
        <LocaleNavLink to="/meteo" data-gloss={intl.formatMessage({id:"menu-wheater", defaultMessage: "Météo"})}>
            <svg className="icone weather" xmlns="http://www.w3.org/2000/svg" width="2rem" viewBox="0 0 31.166 24.933">
                <path fill="currentColor" d="M16.691,17.167a3.107,3.107,0,0,0-4.822-3.835,4.66,4.66,0,0,0-8.752,2.243c0,.095.022.184.028.278a4.663,4.663,0,0,0,1.531,9.072H16.362a3.886,3.886,0,0,0,.329-7.758Zm11.843-4.86a.829.829,0,0,1,0-1.254l2.312-2a.925.925,0,0,0-.536-1.622l-3-.227a.809.809,0,0,1-.726-.995l.728-3.043a.907.907,0,0,0-1.367-.977l-2.557,1.62a.793.793,0,0,1-1.161-.376L21.084.57A.9.9,0,0,0,19.4.57L18.265,3.418a.8.8,0,0,1-1.177.381L14.541,2.186a.907.907,0,0,0-1.367.977L13.9,6.206a.809.809,0,0,1-.726.995L10.2,7.427a.934.934,0,0,0-.541,1.637l1.521,1.317a6.216,6.216,0,0,1,1.061.869,4.522,4.522,0,0,1,2.62-.264,5.442,5.442,0,1,1,5.384,6.147c-.046,0-.089-.013-.135-.014a5.354,5.354,0,0,1,1.469,2.351h1.316a1.56,1.56,0,0,1,.857.257l2.2,1.445a.913.913,0,0,0,1.36-1.011l-.715-2.989a.826.826,0,0,1,.741-1.016l2.978-.226a.925.925,0,0,0,.536-1.622l-2.312-2Zm-9.869,2.919a3.861,3.861,0,0,0,1.593.35,3.9,3.9,0,1,0-3.884-4.018,4.652,4.652,0,0,1,2.29,3.668Z"/>
            </svg>
        </LocaleNavLink>
    )
};
export const BasketIcone:FunctionComponent = ()=>{
    const intl = useIntl();
    const { isDown,setIsDown } = useContext(MenuContext);
    const { sessionId, userId } = useVisitor();
    const { data } = useQuery(CART_BADGE_GQL_DATA, { variables: { sessionId, userId } });

    const renderQuantity = useMemo(()=>{
        if(data?.cart && data?.cart.itemsCount>0 ){
            return(
                <span className="cartQuantity">{data.cart.itemsCount}</span>
            )
        }
    },[data])

    return(
        <LocaleNavLink onClick={()=>setIsDown(false)} to="/checkout/panier" data-gloss={intl.formatMessage({id:"menu-cart", defaultMessage: "Mon panier"})}>
            <FontAwesomeIcon className="icone basket" icon={faCartShopping}/>
            {renderQuantity}
        </LocaleNavLink>
    );
};

