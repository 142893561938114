import React, { FunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import SeoHelmet from "../../components/seo-helmet";
import Banner from "../../components/banner";
import Section from "../../components/wrapper/section";
import TwoColumn from "../../components/wrapper/twoColum";
import { PrimaryButton } from "../../components/button";
import ContactForm from "../../components/form/forms";

import "./putOnSale.scss";
import image from "../../assets/village.jpg";
import { commonDefs } from "../../i18n";

const transDefs = defineMessages({
    seo_title: {
      id: "seo-title-putOnSale",
      defaultMessage: "Agence Maytain | Mettre son bien en vente"
    },
    seo_desc: {
      id: "seo-description-putOnSale",
      defaultMessage: "Vous souhaitez mettre votre bien en Vente? Nous nous chargeons de tout!"
    },
    text_1: {
      id: "putOnSale-intro",
      defaultMessage: "Vous voulez vendre votre bien immobilier, nous nous chargeons de tout:"
    },
    list_1: {
      id: "putOnSale-text-1",
      defaultMessage: "Nous estimons votre bien selon la valeur du marché"
    },
    list_2: {
      id: "putOnSale-text-2",
      defaultMessage: "Nous trouvons le client, organisons les visites et nous nous occupons de la négociation entre les parties"
    },
    list_3: {
      id: "putOnSale-text-3",
      defaultMessage: "Nous planifions toute la vente avec le notaire que vous souhaitez"
    },
    list_4: {
      id: "putOnSale-text-4",
      defaultMessage: "Nous vous accompagnons jusqu’à la remise des clefs"
    },
    list_5: {
      id: "putOnSale-text-5",
      defaultMessage: "Nous nous occupons des changements d’adresse pour l’électricité, les PPE..."
    },
  });

const PutOnSale:FunctionComponent = ()=>{
    const intl = useIntl();

    return (
        <>
            <SeoHelmet
                title={intl.formatMessage(transDefs.seo_title)}
                description={intl.formatMessage(transDefs.seo_desc)}
                canonical="/mettre-en-vente"
            />
            <Banner title={intl.formatMessage(commonDefs.putSale)} />
            <Section className="putOnSale">
                <TwoColumn
                imageSrc={image}
                revert={false}
                title={intl.formatMessage({id:"putOnSale-title", defaultMessage: "Un bien à vendre ?"})}
                subtitle={intl.formatMessage(commonDefs.putSale)}
                >
                    <p>{intl.formatMessage(transDefs.text_1)}</p>
                    <ul>
                        <li>{intl.formatMessage(transDefs.list_1)}</li>
                        <li>{intl.formatMessage(transDefs.list_2)}</li>
                        <li>{intl.formatMessage(transDefs.list_3)}</li>
                        <li>{intl.formatMessage(transDefs.list_4)}</li>
                        <li>{intl.formatMessage(transDefs.list_5)}</li>
                    </ul>
                </TwoColumn>
            </Section>
            <Section title={intl.formatMessage({ id: "form-title", defaultMessage: "Formulaire de contact"})}
                     subtitle={intl.formatMessage(commonDefs.contact)}
                     className="section-form">
                <ContactForm/>
            </Section>
        </>

    );
};

export default PutOnSale;
