import { Moment } from "moment";
import { SearchAccomodationArticles } from "../../models/searchArticles";

type Action = {
    type:"weekly"|"seasonal"|"annual"|"sale";
    direction:string;
    id:string;
    roomsRange:string;
    price:string;
    minSurface:string;
    from:Moment;
    to:Moment;
    minGuests:string;
    objectTypes:string;
    parking:boolean;
    wifi:boolean;
    tv:boolean;
    pet:boolean;
    fireplace:boolean;
    dishwasher:boolean;
}


export default function articlesPaginationReducer(searchData:SearchAccomodationArticles,action:Action){

    if(action.direction==="before"){
        return (
            {
                ...searchData,
                before:action.id,
                last:8,
                after:null,
                first:null,
            }
        )
    }else if(action.direction==="after"){
        return (
            {
                ...searchData,
                after:action.id,
                first:8,
                before:null,
                last:null,
            }
        )
    }else if(action.type==="weekly"){
        return (
            {
                ...searchData,
                after:null,
                first:8,
                before:null,
                last:null,
                qRent:{
                    ...searchData.qRent,
                    roomsRange: !!action.roomsRange ? {
                        from: parseInt(action.roomsRange.split("-")[0]),
                        to: parseInt(action.roomsRange.split("-")[1]),
                    } : null,
                    minGuests:!!action.minGuests?parseInt(action.minGuests):null,
                    objectTypes:!!action.objectTypes?action.objectTypes.split("-"):null,
                    period:!!action.from?{
                        ...searchData.qRent.period,
                        checkIn: action.from,
                        checkOut: action.to
                    }:null,
                    parking:action.parking,
                    wifi:action.wifi,
                    tv:action.tv,
                    pet:action.pet,
                    fireplace:action.fireplace,
                    dishwasher:action.dishwasher,
                }
            }
        )
    }else if(action.type==="sale"){
        return (
            {
                ...searchData,
                after:null,
                first:8,
                before:null,
                last:null,
                qSale:{
                    ...searchData.qSale,
                    roomsRange: !!action.roomsRange ? {
                        from: parseInt(action.roomsRange.split("-")[0]),
                        to: parseInt(action.roomsRange.split("-")[1]),
                    } : null,
                    price: {
                        from: !!action.price?parseInt(action.price.split("-")[0]):0 ,
                        to : !!action.price?parseInt(action.price.split("-")[1]):100000000
                    },
                    minSurface:!!action.minSurface?parseInt(action.minSurface):null,
                    objectTypes:!!action.objectTypes?action.objectTypes.split("-"):null,
                }
            }
        )
    }else if(action.type==="seasonal"){
        return (
            {
                ...searchData,
                after:null,
                first:8,
                before:null,
                last:null,
                qSeasonal:{
                    ...searchData.qSeasonal,
                    roomsRange: !!action.roomsRange ? {
                        from: parseInt(action.roomsRange.split("-")[0]),
                        to: parseInt(action.roomsRange.split("-")[1]),
                    } : null,
                    price: {
                        from: !!action.price?parseInt(action.price.split("-")[0]):0 ,
                        to : !!action.price?parseInt(action.price.split("-")[1]):100000000
                    },
                    minSurface:!!action.minSurface?parseInt(action.minSurface):null,
                    objectTypes:!!action.objectTypes?action.objectTypes.split("-"):null,
                    parking:action.parking,
                    fireplace:action.fireplace,
                    dishwasher:action.dishwasher,
                }
            }
        )

    }else if(action.type==="annual"){
        return (
            {
                ...searchData,
                after:null,
                first:8,
                before:null,
                last:null,
                qAnnual:{
                    ...searchData.qAnnual,
                    roomsRange: !!action.roomsRange ? {
                        from: parseInt(action.roomsRange.split("-")[0]),
                        to: parseInt(action.roomsRange.split("-")[1]),
                    } : null,
                    price: {
                        from: !!action.price?parseInt(action.price.split("-")[0]):0 ,
                        to : !!action.price?parseInt(action.price.split("-")[1]):100000000
                    },
                    minSurface:!!action.minSurface?parseInt(action.minSurface):null,
                    objectTypes:!!action.objectTypes?action.objectTypes.split("-"):null
                }
            }
        )
    }
}
