import React, { FunctionComponent } from "react";
import Banner from "../../components/banner";
import Section from "../../components/wrapper/section";
import TwoColumn from "../../components/wrapper/twoColum";
import { useIntl } from "react-intl";

import "./administration.scss";

import image from "../../assets/chalets-agence-.jpg";
import logo from "../../assets/uspi-logo.png";

const Administration:FunctionComponent = ()=>{
    const intl = useIntl();
    return (
        <>
            <Banner title={intl.formatMessage({id:"admin",  defaultMessage: "Administration"})}/>
            <Section className="bottom100 administration">
                <TwoColumn
                imageSrc={image}
                revert={false}
                title={intl.formatMessage({id:"admin-text-1",  defaultMessage: "Administration de proximité"})}
                subtitle={intl.formatMessage({id:"admin-text-2",  defaultMessage: "Administration à Morgins"})}
                >
                <p>
                    {intl.formatMessage({id:"admin-text-3",  defaultMessage: "L’Agence Maytain administre plus de 40 PPE à Morgins à la grande satisfaction des propriétaires."})}
                    <br/>
                    <br/>
                    {intl.formatMessage({id:"admin-text-4",  defaultMessage: "L’expérience d’un professionnel compétent est souhaitable si l’on veut assurer la bonne gestion d’une PPE et l’harmonie d’une copropriété. L’administration requiert des connaissances pointues de la législation cantonale et fédérale, en comptabilité et budget, dans la planification des travaux de maintenance et rénovation."})}
                    <br/>
                    <br/>
                    {intl.formatMessage({id:"admin-text-5",  defaultMessage: "D’où l’intérêt de nous faire confiance pour gérer et valoriser votre bâtiment dans le temps."})}<br/>
                    <br/>
                    {intl.formatMessage({id:"admin-text-6",  defaultMessage: "Nous sommes membres de l’API-WIT (Association des Professionnels de l’Immobilier) et de l’USPI-CIV (Union Suisse des Professionnels de l’Immobilier - Chambre Immobilière du Valais), associations professionnelles au niveau régional, cantonal et fédéral, en tant qu’agence patentée."})}
                </p>
                <div className="section-logo">
                    <img src={logo} alt="USPI logo"/> {/*MUST BE A LINK?*/}
                </div>
                </TwoColumn>
            </Section>
        </>
    )
}

export default Administration
